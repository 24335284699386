export const FEATURE_TOGGLES = {
  ACX_WEB_ENABLE_ALCHEMER_SURVEY: 'acx-web-enable-alchemer-survey',
  ACX_WEB_ENABLE_NAV_TITLES: 'acx-web-enable-nav-titles',
  ACX_WEB_ENABLE_FAQ_RESOLVE_DEBT: 'acx-web-enable-faq-resolve-debt',
  ACX_WEB_ENABLE_FAQ_PERSONAL_LOAN: 'acx-web-enable-faq-personal-loan',
  ACX_WEB_ENABLE_FAQ_HOME_EQUITY_LOAN: 'acx-web-enable-faq-home-equity-loan',
  ACX_WEB_ENABLE_SITE_WIDE_BANNER: 'acx-web-enable-site-wide-banner',
  ACX_WEB_ENABLE_ARTICLE_HOOK: 'acx-web-enable-article-hook',
  HOME_EQUITY_LOAN_MP_EXPERIMENT: 'home-equity-loan-mp-experiment',
  ACX_DTI_CONTENT_EXPERIMENT: 'acx-dti-content-experiment',

  // Temporary release flags. Remove here and in LaunchDarkly once page is released
  ACX_WEB_ENABLE_ACCELERATION_LOAN: 'acx-web-enable-acceleration-loan',
  ACX_ENABLE_PRODUCT_SPECIFIC_PARAM: 'acx-enable-product-specific-query-experiment',
  ACX_WEB_ENABLE_DEBT_PAYOFF_CALCULATOR: 'acx-web-enable-debt-assessment-tool',
  ACX_WEB_ENABLE_DEBT_PAYOFF_CALCULATOR_MXP: 'acx-web-enable-debt-assessment-tool-mxp',
  ACX_WEB_ENABLE_SIGN_IN_MXP: 'acx-web-enable-sign-in-mxp',
  ACX_WEB_ENABLE_PHONE_NUMBERS: 'acx-enable-phone-numbers',
  ACX_WEB_ENABLE_AUTHORS: 'acx-web-enable-authors',
  ACX_WEB_ENABLE_MXP_DEBT_CHECKUP: 'acx-web-enable-mxp-debt-checkup',
  ACX_WEB_ENABLE_DIRECT_MAIL_LINK: 'acx-web-enable-direct-mail-link',

  ACX_WEB_ENABLE_CACHE_PURGE_SLACK_ALERTS: 'acx-website-cache-purge-slack-alerts',
  ACX_WEB_CLEAR_CACHE: 'acx-apollo-memory-cache-clear',
}

export const FEATURE_EXPERIMENTS_TOGGLES = [
  'acx-pl-hub-template-experiment',
  'acx-experiment-home-hero-v2',
  'acx-homepage-trust-pilot-trustmarker-experiment',
  'acx-hl-hub-template-experiment',
  'acx-ahl-extended-use',
  'acx-home-page-pzn',
  'acx-dti-calculator-v2',
  'acx-good-molo-skeleton-experiment',
  FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS,
  FEATURE_TOGGLES.HOME_EQUITY_LOAN_MP_EXPERIMENT,
  FEATURE_TOGGLES.ACX_DTI_CONTENT_EXPERIMENT,
]

export const SESSION_BASED_FEATURE_TOGGLES = [FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS]

export const EVENT_TYPES = {
  START: 'start',
  CONVERSION: 'conversion',
  TRACK: 'track',
}

// values should be all lower case
export const VARIATIONS = {
  CONTROL: 'control',
  VARIATION_A: 'variation_a',
  VARIATION_B: 'variation_b',
  VARIATION_C: 'variation_c',
  UNASSIGNED: 'unassigned',
  OFF: 'off',
}

export default FEATURE_TOGGLES
